
export default {
  name: 'ImageTextVerticalColumn',
  props: {
    image: {
      type: Array,
    },
    preline: {
      type: String,
    },
    headline: {
      type: String,
    },
    text: {
      type: Array,
    },
    cta: {
      type: Array,
    },
    openingHours: {
      type: Array,
    },
    headlineClasses: {
      type: String,
    },
    readMoreText: {
      type: Array,
    },
  },
  methods: {
    renderHeader(text) {
      if (!text.includes('|'))
        return text;

      const headerArray = text.split('|');
      let newText = '';

      headerArray.forEach((text, index) => {
        if(index > 0)
          newText += '<span class="text-gray font-thin">|</span>';
        newText += `<span>${text}</span>`;
      });

      return newText;
    },
  },
};
